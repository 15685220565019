import { IS_CA, IS_NA, IS_JEWLR, jewlr_t } from "helpers/application"

export const showServiceDisruptionNotice = () => IS_CA()

export const freeGiftPromoEnabled = () =>
  sl.config.free_gift_promo && sl.config.free_gift_promo.enable

// Shows extended free resizing in PDP and /info/ring_sizing
// (usually only used at Christmas and Mother's Day times)
export const showHolidayResize = () => sl.config.show_holiday_resize
export const holidayResizeDates = () => ({
  end: sl.config.holiday_resize_dates?.end,
  start: sl.config.holiday_resize_dates?.start,
})

// Order resize, return, repair
export const showHolidayRma = () =>
  IS_JEWLR() && new Date() < new Date("2025-01-15")
export const holidayRmaDates = {
  end: "January 15, 2025",
  start: "November 20, 2024",
}

// Shipping info page
export const showHolidayShippingInfo = () =>
  IS_JEWLR() &&
  new Date() >= new Date("2024-11-01") &&
  new Date() < new Date(sl.config.guaranteed_receive_date)
// Show "Shop Early" copy on Shipping Info page
export const showEarlyShippingInfo = () =>
  IS_JEWLR() && new Date() < new Date("2024-12-24")
// Show Now CTA on Shipping Info page
export const holidayCTALink = () => "/"

// Show cutoff instant gift messaging in shipping info page, cart and order change form
export const showHolidayCutoff = false
// new Date() >= new Date("2023-05-11") && new Date() < new Date("2023-05-14")

export const isInCutoffPeriod = () => {
  const cutoffDates = sl.config.cutoff_dates
  const startDate = cutoffDates?.length && new Date(cutoffDates[0])
  const endDate =
    cutoffDates?.length && new Date(cutoffDates[cutoffDates.length - 1])
  const now = new Date()

  return startDate && endDate && now > startDate && now < endDate
}

// PDP shipping info box
export const showHolidayShippingInfoPDP = () =>
  sl.config.show_holiday_shipping_info_pdp
export const showHolidayShipMethodPDP = IS_JEWLR() // is_na || is_au

export const showGiftCardSale = () => new Date() < new Date("2021-01-01")
// Shows instant gift promo message in cart, PDP instant gift block image
export const showInstantGiftHoliday = () =>
  sl.config.sale_key.includes("mothers_day")
// Show instant gift homepage block
export const showInstantGiftHomepage = () =>
  sl.config.sale_key.includes("no_delivery")
// Show mother's day instant gift video /info/instant_gift main banner and steps image
export const showMothersInstantGiftAssets = false // sl.config.sale_key.includes("mothers_day")

/**
 * Dictates instant gift video data
 *
 * Video: christmas, valentines, mothersday, generic, 2024-mothers-day
 *
 * Storyboard ID:
 * holiday: 144721
 * vday: 115155
 * generic: 44633
 * mothersday: 124326
 * fathersday: 128198
 * 2024-mothers-day: 44633
 *
 * Message:
 * christmas: "I promise to love you for all that you are, all that you have been, and all you are yet to be.\nMerry Christmas my love!\nYours always,"
 * mothersday: "For everything we are today, your love showed us the way! Happy Mother’s Day!\nWe love you,"
 * generic: "You are my heart's adventure – hold my hand and I’ll go anywhere with you!\nLove always,"
 * valentines: "You’re my heart’s adventure!\nBe mine this Valentine’s Day.\nYours always,"
 * 2024-mothers-day: "Happy Mother’s Day\n"
 *
 * Recipient:
 * mothersday: "Mom"
 * 2024-mothers-day: "Mom"
 * Sender:
 * mothersday: "Ashley and Daniel"
 * 2024-mothers-day: "We love you"
 *
 * Main Banner: generic, holiday, valentines, mothersday
 */
export const instantGiftVideoData = () =>
  showHolidayShippingInfoPDP()
    ? {
        delivery_date: "Dec 27, 2024",
        giftMsg:
          "I promise to love you for all that you are, all that you have been, and all you are yet to be.\nMerry Christmas my love!\nYours always,",
        main_banner: "holiday",
        main_banner_folder: null,
        recipient_name: "Jennifer",
        sender_name: "Michael",
        storyboard_id: () => 144721,
        video: "holiday",
      }
    : {
        delivery_date: "Jan 26, 2025",
        giftMsg:
          "You are my heart's adventure – hold my hand and I’ll go anywhere with you!\nLove always,",
        main_banner: "generic",
        main_banner_folder: null,
        recipient_name: "Ashely",
        sender_name: "Daniel",
        storyboard_id: () => 44633,
        video: "generic",
      }

// Override default instant gift demo details
export const instantGiftDemoData = {}
